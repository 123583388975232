@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles can go below */
body {
  @apply bg-gray-900 text-gray-100;
  font-family: 'Inter', sans-serif;
}

/* Custom scrollbar for modern look */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #4B5563; /* Tailwind's gray-600 */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #6B7280; /* Tailwind's gray-500 */
}


/* Card component styling */
.card {
  @apply bg-gray-800 p-6 rounded-lg shadow-lg;
}

/* Button component styling */
.button {
  @apply bg-indigo-600 text-white py-2 px-4 rounded-lg shadow-md transition duration-300 ease-in-out;
}

.button:hover {
  @apply bg-indigo-700;
}

.button:disabled {
  @apply bg-gray-700 cursor-not-allowed;
}

/* Custom styles for react-calendar */
.react-calendar {
background-color: #2D3748; /* bg-gray-800 */
color: #E2E8F0; /* text-gray-100 */
border-radius: 0.5rem; /* rounded-lg */
box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05); /* shadow-lg */
padding: 1rem;
}

.react-calendar__tile {
background: transparent;
color: #E2E8F0; /* text-gray-100 */
padding: 0.5rem;
border-radius: 0.375rem; /* rounded-md */
transition: background-color 0.3s;
}

.react-calendar__tile--now {
background: #4A5568; /* bg-gray-700 */
}

.react-calendar__tile--active {
background: #5A67D8; /* bg-indigo-600 */
color: white;
}

.react-calendar__tile--hasActive {
background: #2C5282; /* bg-blue-800 */
}

.react-calendar__tile:hover {
background: #4A5568; /* bg-gray-700 */
cursor: pointer;
}

.react-calendar__month-view__days__day--weekend {
color: #63B3ED; /* text-blue-400 */
}

.react-calendar__tile--range {
background: #5A67D8; /* bg-indigo-600 */
color: white;
}

.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
background: #434190; /* bg-indigo-700 */
color: white;
}